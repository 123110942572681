<template>
  <div class="tw-absolute tw-text-xl tw-left-2 tw-bottom-2">
    <div
      v-for="user in users"
      :key="user.id"
      :class="user.name === $auth.user().name ? 'tw-text-green-300' : 'tw-text-yellow-300'"
    >
      {{ user.name }}
      <template v-if="user.dj">
        (DJ)
      </template>
    </div>
  </div>
</template>

<script>
import { sync } from 'vuex-pathify';

export default {
  computed: {
    ...sync([
      'online/users', //
    ]),
  },

  watch: {
    users: {
      handler() {
        // console.log('UserList.online.users', this.users);
      },
      deep: true,
    },
  },
};
</script>
